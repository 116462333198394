export const helpItems = [
    {
        title: 'VEHICLES',
        items: ['Cars', 'Trucks', 'Heavy Duty', 'Motorcycles', 'ATVs', 'Utvs', 'Rvs']
    },
    {
        title: 'EQUIPMENT',
        items: ['Agricultural', 'Industrial', 'Medical', 'Logistical', 'Military']
    },
    {
        title: 'BOATS',
        items: ['Kayaks', 'Jetboats', 'Pontoon', 'Tritoon', 'Watercraft', 'Yacht']
    },
    {
        title: 'CARGO',
        items: ['Metal', 'Building Materials', 'Construction', 'Liquids', 'Food & Beverage', 'Refrigerated', 'Paper', 'Retail',]
    }
]